import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { PromotionHeader } from '../models/promotion-header.model';
import { PromotionGroup } from '../models/promotion-group.model';
import { PromotionCategory } from '../models/promotion-category.model';
import { NotifyService } from '../services/notify.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-promotion-group-selection',
  templateUrl: './promotion-group-selection.component.html',
  styleUrls: ['./promotion-group-selection.component.css']
})
export class PromotionGroupSelectionComponent {
  @ViewChild('promotionGroupSelectionGrid', {static: false}) dataGrid: DxDataGridComponent;
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();
  @Output() closeChild: EventEmitter<any> = new EventEmitter();
  @Input() parent: string;
  header: PromotionHeader;
  promotionGroups$: Observable<PromotionGroup[]>;
  promotionCategories$: Observable<PromotionCategory[]>;
  slotted: boolean = false;
  dataLoading: boolean = true;
  gridMode: string = 'multiple';
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
              private notifyService: NotifyService) {
    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');
    }
    
    this.promotionCategories$ = this.crudService.newGetData(`${this.apiUrl}planning-categories`);
    this.loadPromotionGroups();
  }

  loadPromotionGroups() {
    this.promotionGroups$ = this.crudService.newGetData(`${this.apiUrl}promotion-groups`).pipe(
      map((result: any) => {
        this.dataLoading = false;
        return result.data;
      })
    );
  }

  selectPromotionGroups(e: any) {
    let userDetails: any = {};
    if(localStorage.getItem('userDetails') !== null) {
      userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
    }
    const promotionGroups: PromotionGroup[] = this.dataGrid.instance.getSelectedRowsData();
    if(!this.slotted) {
      promotionGroups.forEach((group: PromotionGroup) => {
        group.slot_type = '';
      });
    }
    const values: any = {
      location_id: userDetails.data.location_id,
      promotion_header_id: this.header.id,
      buying_group_id: this.header.buying_group_id,
      promotion_groups: promotionGroups
    };
    this.crudService.insertData(`${this.apiUrl}insert-promotion-details`, values).subscribe((result: any) => {
      this.notifyService.tellUser(result);
      console.log('Inserted Promotion Details: ', result);
      this.loadParentData.emit();
      values.promotion_details_groups_id = result.data.id
      this.closeChild.emit(values);
    });
  }

  slottedPromotionGroups(e: any) {
    this.dataLoading = true;
    if(e.value) {
      this.promotionGroups$ = this.crudService.newGetData(`${this.apiUrl}selected-promotion-groups?`+
        `promotion_period_id=${this.header.promotion_period_id}&`+
        `promotion_start=${this.header.promotion_start}&`+
        `promotion_end=${this.header.promotion_end}&`+
        `buying_group_id=${this.header.buying_group_id}`).pipe(
        map((result: any) => {
          this.slotted = true;
          this.dataLoading = false;
          return result.data;
        })
      );
    } else {
      this.dataLoading = true;
      this.slotted = false;
      this.loadPromotionGroups();
    }
  }

  onHiding(event: any) {
    this.dataGrid.instance.clearSelection();
    this.dataGrid.instance.clearFilter();
  }

}
