import packageJson from '../../package.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isLocal: true,
  baseApiUrl: 'https://develop-promoflo-api.otdapi.com.au/api/',
  authApiUrl: 'https://develop-auth.otdapi.com.au/api/',
  authUiUrl: 'https://develop-login.otdapi.com.au/',

  // baseApiUrl: 'http://localhost:8001/api/',
  // authApiUrl: 'http://localhost:8000/api/',
  // authUiUrl: 'http://localhost:4201/',

  themes: [
    'material.blue.light.compact',
    'material.blue.dark.compact'
  ],
  bgColours: [
    '#ffffff',
    '##363640'
  ],

  version: packageJson.version + '-dev',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
