<dx-data-grid
    #promotionGroupSelectionGrid
    [dataSource]="promotionGroups$ | async"
    keyExpr="id"
    [rowAlternationEnabled]="true"
    [repaintChangesOnly]="true"
    height="95%"
  >
    <dxo-selection
      [mode]="gridMode"
    ></dxo-selection>
    <dxo-toolbar>
      <dxi-item>
        <dx-switch
          [value]="false"
          hint="Filter for slotted products only"
          [switchedOnText]="'Promotion Products'"
          [switchedOffText]="'All Products'"
          [visible]="true"
          (onValueChanged)="slottedPromotionGroups($event)"></dx-switch>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="exportselected" hint="Add selected products" (onClick)="selectPromotionGroups($event)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Search..."
    ></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
    <dxo-paging [pageSize]="100"></dxo-paging>
    <dxi-column dataField="id" dataType="number" [visible]="false"></dxi-column>
    <dxi-column dataField="slot_type" dataType="string" [visible]="false"></dxi-column>
    <dxi-column dataField="promotion_group_name" dataType="string" caption="Promotion Group"></dxi-column>
    <dxi-column dataField="planner_category_id" dataType="string" caption="Category">
      <dxo-lookup [dataSource]="promotionCategories$ | async" valueExpr="id" displayExpr="planning_category_name"></dxo-lookup>
    </dxi-column>
  </dx-data-grid>

  <dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="dataLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
