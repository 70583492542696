import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportStylesService {

  constructor() { }

  getStyles() {
    return {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, -10, 0],
        alignment: 'center'
      },
      supplierStyle: {
        fontSize: 10,
        bold: true
      },
      mixMatchHeaderStyle: {
        fontSize: 10,
        bold: true,
        fillColor: '#3d8dbf',
        color: 'white'
      },
      mixMatchAvgStyle: {
        fontSize: 8,
        bold: true,
        fillColor: '#3d8dbf',
        color: 'white'
      },
      promoInrowStyle:{
        fontSize: 10,
        bold: true,
        fillColor: '#484848'
      },
        footer: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: 'center'
        },
        bodyStyle: {
          fontSize: 8
        },
        bodyStyleBold: {
          fontSize: 8,
          bold: true
        },
        bodyStyleLargeFont: {
          fontSize: 12,
          bold: true,
          fillColor: '#dddddd'
        },
        bodyStyleMemberAdvice:{
          fontSize: 6
        },
        edlpStyle: {
          fontSize: 8,
          color: 'green'
        },
        coreStyle: {
          color: '#ff0000',
          fontSize:8
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'white',
          fillColor: '#182351',
          alignment: 'center'
        },
        
          coreRange: {
            bold: true,
            color: 'red',
            fontSize: 8
        },
        copyrightFooter:{
          fontSize: 8,
          alignment: 'right',
          marginRight: 40

        },
        footerDisclaimer:{
          fontSize: 6,
          alignment: 'left',
          marginLeft: 40
        },
        subheaderStyle:{
          alignment:'left'
        },
        promoStyle: {
          fillColor: '#6edd60', // This sets the background color to green
          fontSize:10,
          color: '#313131',
          alignment: 'center',
          bold: false,
        },
        rebateStyle:{
          fillColor: '#e76969',
          fontSize:10,
          color: '#313131',
          alignment: 'center',
          bold: false,
        },
        baseStyle:{
          fontSize:10,
          bold: true,
        },
        headerDateStyles:{
          alignment:'center',
          bold: true,
          fontSize: 10,
          color: 'white',
          fillColor: '#182351',
        },
        separatorStyle: {
           // Adjust top and bottom margin as needed
          fillColor: '#3d8dbf',  // Blue color
         
          fontSize: 1
        },
        separatorBorder:{
         fillColor:'#black'
        },
        categoryHeader:{
          alignment:'left',
          fontSize: 9,
          bold: true,
          fillColor: '#63b8ce',
          color: 'black',
        },
        
        buyinGreenEmptyCell:{
          fillColor: 'white',
          fontSize:10,
          alignment: 'center',
          bold: false,
        },
        buyingreenPCellStyle:{
          fillColor: 'white',
          color: '#313131',
          fontSize:10,
          alignment: 'center',
          bold: false,
          margin: [10, 10, 10, 10],
        },
        dayHeader:{
          bold: true,
          fontSize: 10,
          color: 'white',
          
          alignment: 'center'
        },
        monthHeader:{
          bold: false,
          fontSize: 10,
          color: 'white',
          
          alignment: 'center'
        },
        promoHeader:{
          bold: false,
          fontSize: 9,
          color: 'white',
          alignment: 'center'
        },
        promoDateHeader:{
          bold: true,
          fontSize: 10,
          color: 'black',
          alignment: 'left',
          //fillColor:'#182351'
        },
        promotionSummaryPromotionInTable:{
          bold: true,
          fontSize: 8,
          color: 'white',
          alignment: 'left',
          fillColor:'#484848',
          decoration: ''
        },
        promosummaryDateHeader:{
          bold: true,
          fontSize: 10,
          color: 'white',
          alignment: 'left',
          fillColor:'#182351'
        },
        categorySummaryHeader:{
          alignment:'left',
          fontSize: 7,
          bold: true,
          fillColor: '#1d2b62',
          color: 'white',
        },
        // ... other styles
      };
    }
  }
  