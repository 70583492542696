import { Injectable } from '@angular/core';
import { Config } from '../models/config.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class GroupProductService {
   /* groupByAlphaNumeric(products: any[], productValueFn: (product: any) => string, supplierHeaders: any[]): any[] {
      const groupedProducts: any = {};

    // Initialize groups with supplier headers to ensure all descriptions are included
    supplierHeaders.forEach(header => {
      const promotionId = header.id.toString();
      if (!groupedProducts[promotionId]) {
          groupedProducts[promotionId] = {};
      }

      // Initializing with empty arrays for products per supplier
      const description = header.description || 'Unknown';
      if (!groupedProducts[promotionId][description]) {
          groupedProducts[promotionId][description] = [];
      }
  });

  // Populate products into the initialized groups
  products.forEach(product => {
      const promotionId = product.promotion_header_id || 'Unknown';
      const supplierName = productValueFn(product) || 'Unknown';

      if (!groupedProducts[promotionId]) {
          groupedProducts[promotionId] = {};
      }

      if (!groupedProducts[promotionId][supplierName]) {
          groupedProducts[promotionId][supplierName] = [];
      }

      groupedProducts[promotionId][supplierName].push(product);
  });

  let result:any = [];

  // Flatten the result object
  for (const promotionId in groupedProducts) {
      if (groupedProducts.hasOwnProperty(promotionId)) {
          const suppliers = groupedProducts[promotionId];
          const sortedSuppliers = Object.keys(suppliers).sort();

          // Extract and format each supplier and their products
          sortedSuppliers.forEach(supplierName => {
              if (suppliers[supplierName]) {
                  result.push({
                      promotionId: promotionId,
                      supplier: supplierName,
                      products: suppliers[supplierName]
                  });
              }
          });
      }
  }

  // Optional: Sort the final result by supplier name, then by promotionId
  result.sort((a:any, b:any) => {
      const supplierCompare = a.supplier.localeCompare(b.supplier);
      if (supplierCompare !== 0) {
          return supplierCompare;
      }
      return a.promotionId.localeCompare(b.promotionId);
  });

  return result;
  }
  */

  groupByAlphaNumeric(products: any[], productValueFn: (product: any) => string, supplierHeaders: any[]): any[] {
    const groupedProducts: any = {};

    // Initialize groups with supplier headers to ensure all descriptions are included
    supplierHeaders.forEach(header => {
        const promotionId = header.id.toString();
        if (!groupedProducts[promotionId]) {
            groupedProducts[promotionId] = {};
        }

        // Initializing with empty arrays for products per supplier
        const description = header.description || 'Not On Promotion';
        if (!groupedProducts[promotionId][description]) {
            groupedProducts[promotionId][description] = [];
        }
    });

    // Populate products into the initialized groups
    products.forEach(product => {
        const promotionId = product.promotion_header_id || 'Not On Promotion';
        const supplierName = productValueFn(product) || 'Not On Promotion';

        if (!groupedProducts[promotionId]) {
            groupedProducts[promotionId] = {};
        }

        if (!groupedProducts[promotionId][supplierName]) {
            groupedProducts[promotionId][supplierName] = [];
        }

        groupedProducts[promotionId][supplierName].push(product);
    });

    let result: any = [];

    // Flatten the result object
    for (const promotionId in groupedProducts) {
        if (groupedProducts.hasOwnProperty(promotionId)) {
            const suppliers = groupedProducts[promotionId];
            const sortedSuppliers = Object.keys(suppliers).sort();

            // Extract and format each supplier and their products, skip empty arrays
            sortedSuppliers.forEach(supplierName => {
                if (suppliers[supplierName] && suppliers[supplierName].length > 0) {
                    result.push({
                        promotionId: promotionId,
                        promotion: supplierName,
                        products: suppliers[supplierName]
                    });
                }
            });
        }
    }

    // Optional: Sort the final result by supplier name, then by promotionId
    result.sort((a: any, b: any) => {
        const supplierCompare = a.promotion.localeCompare(b.promotion);
        if (supplierCompare !== 0) {
            return supplierCompare;
        }
        return a.promotionId.localeCompare(b.promotionId);
    });

    return result;
}

groupByProductPlanningCategory(products: any[], productValueFn: (product: any) => string, supplierHeaders: any[]): any[] {
    const headersMap = new Map(supplierHeaders.map(header => [header.id.toString(), header])); // Assuming 'id' is the promotionId
    const groupedProducts: any = {};

    // Initialize groupedProducts with all headers to ensure each has an entry
    supplierHeaders.forEach(header => {
        groupedProducts[header.id] = {
            promotionId: header.id.toString(),
            promotionName: 'Not On Promotion', // Default name, update if found in products
            promotion_end: 'Unknown',
            promotion_start: 'Unknown',
            suppliers: {
                'Empty Header': [] // Default empty supplier array
            }
        };
    });

    for (const product of products) {
        const promotionId = product.promotion_header_id.toString();
        const supplierName = productValueFn(product) || 'Unknown';

        // Initialize if not already set from headers
        if (!groupedProducts[promotionId]) {
            groupedProducts[promotionId] = {
                promotionId: promotionId,
                promotionName: 'Not On Promotion',
                promotion_end: 'Unknown',
                promotion_start: 'Unknown',
                suppliers: {}
            };
        }

        // Replace the default 'Empty Header' if adding the first product
        if (groupedProducts[promotionId].suppliers['Empty Header'] && groupedProducts[promotionId].suppliers['Empty Header'].length === 0) {
            delete groupedProducts[promotionId].suppliers['Empty Header'];
        }

        if (!groupedProducts[promotionId].suppliers[supplierName]) {
            groupedProducts[promotionId].suppliers[supplierName] = [];
        }

        groupedProducts[promotionId].suppliers[supplierName].push(product);
        // Update promotion name if it's available in the header map
        if (headersMap.has(promotionId)) {
            groupedProducts[promotionId].promotionName = headersMap.get(promotionId).description || 'Unknown';
            groupedProducts[promotionId].promotion_start = headersMap.get(promotionId).promotion_start || 'Unknown';
            groupedProducts[promotionId].promotion_end = headersMap.get(promotionId).promotion_end || 'Unknown';
        }
    }

    // Now sort each supplier's product list by 'product_name'
    for (const promotion in groupedProducts) {
        for (const supplier in groupedProducts[promotion].suppliers) {
            groupedProducts[promotion].suppliers[supplier].sort((a:any, b:any) => this.naturalSorts(a, b, 'product_name'));
        }
    }
    const result = [];


    for (const promotionId in groupedProducts) {
        const promotionGroup: any = {
            promotionName: groupedProducts[promotionId].promotionName,
            promotion_start: groupedProducts[promotionId].promotion_start,
            promotion_end: groupedProducts[promotionId].promotion_end,
            promotionId: groupedProducts[promotionId].promotionId,
            suppliers: []
        };

        for (const supplierName in groupedProducts[promotionId].suppliers) {
            promotionGroup.suppliers.push({
                supplier: supplierName,
                products: groupedProducts[promotionId].suppliers[supplierName]
            });
        }

        // Sort the suppliers within each promotion group
        promotionGroup.suppliers.sort((a:any, b:any) => this.naturalSorts(a, b, 'supplier'));

        result.push(promotionGroup);

    }
    result.sort((a:any, b:any) => this.naturalSorts(a, b, 'promotionName'));

    console.log(result);
    return result;
}

groupByForMembersAdvice(products: any[], supplierHeaders: any[]): any[] {
    const groupedByHeaderId: { [key: string]: any } = {};

    // Initialize groups with supplier headers to ensure all descriptions are included
    supplierHeaders.forEach(header => {
        const headerId = header.id.toString();
        if (!groupedByHeaderId[headerId]) {
            groupedByHeaderId[headerId] = {
                description: header.description || 'Unknown',
                buying_start: header.buying_start || 'Unknown',
                buying_end: header.buying_end || 'Unknown',
                groups: {}
            };
        }
    });

    // Populate products into the initialized groups
    products.forEach(product => {
        const headerId = product.promotion_header_id.toString();
        if (!groupedByHeaderId[headerId]) {
            groupedByHeaderId[headerId] = {
                description: 'Unknown', // Fallback if header is not predefined
                buying_start: 'Unknown',
                buying_end: 'Unknown',
                groups: {}
            };
        }

        const groupId = product.promotion_group_id.toString();
        if (!groupedByHeaderId[headerId].groups[groupId]) {
            groupedByHeaderId[headerId].groups[groupId] = {
                groupName: product.promotion_group_name || 'Unknown',
                products: []
            };
        }

        groupedByHeaderId[headerId].groups[groupId].products.push(product);
    });

    // Convert the map into a sorted array
    const result = Object.keys(groupedByHeaderId).map(headerId => {
        const header = groupedByHeaderId[headerId];
        const groupsArray = Object.keys(header.groups).map(groupId => {
            // Sort products within each group by product_name
            header.groups[groupId].products.sort((a: any, b: any) => a.product_name.localeCompare(b.product_name));

            return {
                groupId,
                groupName: header.groups[groupId].groupName,
                products: header.groups[groupId].products
            };
        });

        // Sort groups within each header
        groupsArray.sort((a: any, b: any) => a.groupName.localeCompare(b.groupName));

        return {
            headerId: headerId,
            description: header.description,
            buying_start: header.buying_start,
            buying_end: header.buying_end,
            groups: groupsArray
        };
    });

    // Optionally, sort headers by description
    result.sort((a: any, b: any) => a.description.localeCompare(b.description));

    return result;
}

  groupByProductValue(products: any[], productValueFn: (product: any) => string, supplierHeaders: any[]): any[] {
    const headersMap = new Map(supplierHeaders.map(header => [header.id.toString(), header])); // Assuming 'id' is the promotionId
    const groupedProducts: any = {};

    // Initialize groupedProducts with all headers to ensure each has an entry
    supplierHeaders.forEach(header => {
        groupedProducts[header.id] = {
            promotionId: header.id.toString(),
            promotionName: 'Unknown', // Default name, update if found in products
            promotion_end: 'Unknown',
            promotion_start: 'Unknown',
            suppliers: {
                'Empty Header': [] // Default empty supplier array
            }
        };
    });

    for (const product of products) {
        const promotionId = product.promotion_header_id.toString();
        const supplierName = productValueFn(product) || 'Unknown';

        // Initialize if not already set from headers
        if (!groupedProducts[promotionId]) {
            groupedProducts[promotionId] = {
                promotionId: promotionId,
                promotionName: 'Unknown',
                promotion_end: 'Unknown',
                promotion_start: 'Unknown',
                suppliers: {}
            };
        }

        // Replace the default 'Empty Header' if adding the first product
        if (groupedProducts[promotionId].suppliers['Empty Header'] && groupedProducts[promotionId].suppliers['Empty Header'].length === 0) {
            delete groupedProducts[promotionId].suppliers['Empty Header'];
        }

        if (!groupedProducts[promotionId].suppliers[supplierName]) {
            groupedProducts[promotionId].suppliers[supplierName] = [];
        }

        groupedProducts[promotionId].suppliers[supplierName].push(product);
        // Update promotion name if it's available in the header map
        if (headersMap.has(promotionId)) {
            groupedProducts[promotionId].promotionName = headersMap.get(promotionId).description || 'Unknown';
            groupedProducts[promotionId].promotion_start = headersMap.get(promotionId).promotion_start || 'Unknown';
            groupedProducts[promotionId].promotion_end = headersMap.get(promotionId).promotion_end || 'Unknown';
        }
    }

    // Now sort each supplier's product list by 'product_name'
    for (const promotion in groupedProducts) {
        for (const supplier in groupedProducts[promotion].suppliers) {
            groupedProducts[promotion].suppliers[supplier].sort((a:any, b:any) => this.naturalSorts(a, b, 'product_name'));
        }
    }
    const result = [];


    for (const promotionId in groupedProducts) {
        const promotionGroup: any = {
            promotionName: groupedProducts[promotionId].promotionName,
            promotion_start: groupedProducts[promotionId].promotion_start,
            promotion_end: groupedProducts[promotionId].promotion_end,
            promotionId: groupedProducts[promotionId].promotionId,
            suppliers: []
        };

        for (const supplierName in groupedProducts[promotionId].suppliers) {
            const supplierProducts = groupedProducts[promotionId].suppliers[supplierName];
            const groupComment = supplierProducts.length > 0 && supplierProducts[0].promotion_group_comment
                             ? supplierProducts[0].promotion_group_comment
                             : 'No comment'; // Default comment if not available or if there are no products

            promotionGroup.suppliers.push({
                supplier: supplierName,
                groupComment: groupComment,
                products: groupedProducts[promotionId].suppliers[supplierName]
            });
        }

        // Sort the suppliers within each promotion group
        promotionGroup.suppliers.sort((a:any, b:any) => this.naturalSorts(a, b, 'supplier'));

        result.push(promotionGroup);

    }
    const edv = 'EDV'; // Define the promotionName to be last

    result.sort((a: any, b: any) => {
    if (a.promotionName === edv) return 1; // Move 'a' to the end
    if (b.promotionName === edv) return -1; // Move 'b' to the end
    return this.naturalSorts(a, b, 'promotionName'); // Default sorting
    });

    console.log(result);
    return result;
}
/*
groupBy(products: any[], productValueFn: (product: any) => string): any[] {
        const groupedProducts: any = {};

        for (const product of products) {
            const promotionId = product.promotion_header_id || 'Unknown';
            const supplierName = productValueFn(product) || 'Unknown';

        if (!groupedProducts[promotionId]) {
          groupedProducts[promotionId] = {};
        }

        if (!groupedProducts[promotionId][supplierName]) {
          groupedProducts[promotionId][supplierName] = [];
        }

        groupedProducts[promotionId][supplierName].push(product);
      }

      const result = [];

      for (const promotionId in groupedProducts) {
        if (groupedProducts.hasOwnProperty(promotionId)) {
          const promotionGroup = {
            promotionId: promotionId,
            suppliers: [] as { supplier: string; products: any[] }[]
          };

          for (const supplierName in groupedProducts[promotionId]) {
            if (groupedProducts[promotionId].hasOwnProperty(supplierName)) {
              promotionGroup.suppliers.push({
                supplier: supplierName,
                products: groupedProducts[promotionId][supplierName]
              });
            }
          }

          result.push(promotionGroup);
        }
      }

      result.sort(this.naturalSort);
      return result;
    }
*/
groupByUniqueThirsty(products: any[], promotionHeaders: any): any[] {
    const groupedByPromotion: any = { 'Beer Week 1': {}, 'Beer Week 2': {}, LA: {}, Other: {}, 'EDV': {} };

    console.log("summary before group:", products);

    // Initialize all groups with promotion headers to ensure every unique header is considered
    for (const header of promotionHeaders) {
        ['Beer Week 1', 'Beer Week 2', 'LA', 'Other', 'EDV'].forEach(type => {
            const key = `${header.id}`;  // Using id as the unique key
            groupedByPromotion[type][key] = {
                promotionName: header.description,  // Display name is the description
                products: {},  // Initially no products
                promotion_start: header.promotion_start,  // Default empty
                promotion_end: header.promotion_end     // Default empty
            };
        });
    }

    // Populate products into the initialized groups
    for (const product of products) {
        const productPromotionId = product.promotion_header_id.toString();  // Convert to string to match string keys
        let slotType = 'Other'; // Default slotType

        // Determine slot type based on conditions
        if (product.promotion_group_name.includes('Beer Week 1')) {
            slotType = 'Beer Week 1';
        } else if (product.promotion_group_name.includes('Beer Week 2')) {
            slotType = 'Beer Week 2';
        } else if (product.slot_type === 'LA') {
            slotType = 'LA';
        } else if (product.promotion_group_name.includes('EDV')) {
            slotType = 'EDV';
        }

        // Check if the product matches the specific group by ID
        if (groupedByPromotion[slotType].hasOwnProperty(productPromotionId)) {
            const promotionGroup = groupedByPromotion[slotType][productPromotionId];

            // Initialize category if it does not exist
            if (!promotionGroup.products[product.planning_category_name]) {
                promotionGroup.products[product.planning_category_name] = [];
            }

            promotionGroup.products[product.planning_category_name].push(product);

            // Update promotion dates if applicable
            if (product.promotion_start && !promotionGroup.promotion_start) {
                promotionGroup.promotion_start = product.promotion_start;
            }
            if (product.promotion_end && !promotionGroup.promotion_end) {
                promotionGroup.promotion_end = product.promotion_end;
            }
        } else {
            console.warn(`Product with ID '${productPromotionId}' does not match any known promotion header.`);
        }
    }

    // Sort and organize the final output
    const result: any = [];

    ['Beer Week 1', 'Beer Week 2', 'LA', 'Other', 'EDV'].forEach(slotType => {
        Object.values(groupedByPromotion[slotType]).forEach((group: any) => {
            const sortedCategories = Object.keys(group.products).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
            const sortedGroup: any = {};

            sortedCategories.forEach(category => {
                sortedGroup[category] = group.products[category].sort((a: any, b: any) => a.promotion_group_name.localeCompare(b.promotion_group_name, undefined, { numeric: true, sensitivity: 'base' }));
            });

            if (Object.keys(sortedGroup).length > 0) {
                group.products = sortedGroup;
                result.push(group);
            }
        });
    });

    // Prioritize entries containing "Beer" in the promotionName for final result sorting
    result.sort((a: any, b: any) => {
        const aContainsBeer = a.promotionName.toLowerCase().includes('beer');
        const bContainsBeer = b.promotionName.toLowerCase().includes('beer');
        const aContainsEdv = a.promotionName.toLowerCase().includes('edv');
        const bContainsEdv = b.promotionName.toLowerCase().includes('edv');
    
        if (aContainsEdv && !bContainsEdv) {
            return 1;
        } else if (!aContainsEdv && bContainsEdv) {
            return -1;
        } else if (aContainsBeer && !bContainsBeer) {
            return -1;
        } else if (!aContainsBeer && bContainsBeer) {
            return 1;
        } else {
            return a.promotionName.localeCompare(b.promotionName, undefined, { numeric: true, sensitivity: 'base' });
        }
    });

    console.log(result);
    return result;  // Return the final structured and sorted array
}

groupByUniqueThirstyPOS(products: any[], promotionHeaders: any): any[] {
    const groupedByPromotion: any = { 'Beer Week 1': {}, 'Beer Week 2': {}, LA: {}, Other: {} };

    console.log("summary before group:", products);

    for (const header of promotionHeaders) {
        ['Beer Week 1', 'Beer Week 2', 'LA', 'Other'].forEach(type => {
            const key = `${header.id}`;
            let promoName = header.description; // Default to description
            if (type === 'LA') {
                promoName = promoName + ' Liquor Alliance'; // Set to "Liquor Alliance" for LA
            }
            groupedByPromotion[type][key] = {
                promotionName: promoName,
                products: {},
                promotion_start: header.promotion_start,
                promotion_end: header.promotion_end
            };
        });
    }

    // Populate products into the initialized groups
    for (const product of products) {
        const productPromotionId = product.promotion_header_id.toString();  // Convert to string to match string keys
        let slotType = 'Other'; // Default slotType

        // Determine slot type based on conditions
        if (product.promotion_group_name.includes('Beer Week 1')) {
            slotType = 'Beer Week 1';
        } else if (product.promotion_group_name.includes('Beer Week 2')) {
            slotType = 'Beer Week 2';
        } else if (product.slot_type === 'LA') {
            slotType = 'LA';
        }

        // Check if the product matches the specific group by ID
        if (groupedByPromotion[slotType].hasOwnProperty(productPromotionId)) {
            const promotionGroup = groupedByPromotion[slotType][productPromotionId];

            // Initialize category if it does not exist
            if (!promotionGroup.products[product.planning_category_name]) {
                promotionGroup.products[product.planning_category_name] = [];
            }

            promotionGroup.products[product.planning_category_name].push(product);

            // Update promotion dates if applicable
            if (product.promotion_start && !promotionGroup.promotion_start) {
                promotionGroup.promotion_start = product.promotion_start;
            }
            if (product.promotion_end && !promotionGroup.promotion_end) {
                promotionGroup.promotion_end = product.promotion_end;
            }
        } else {
            console.warn(`Product with ID '${productPromotionId}' does not match any known promotion header.`);
        }
    }

    // Sort and organize the final output
    const result: any = [];

    ['Beer Week 1', 'Beer Week 2', 'LA', 'Other'].forEach(slotType => {
        Object.values(groupedByPromotion[slotType]).forEach((group: any) => {
            const sortedCategories = Object.keys(group.products).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
            const sortedGroup: any = {};

            sortedCategories.forEach(category => {
                sortedGroup[category] = group.products[category].sort((a: any, b: any) => a.promotion_group_name.localeCompare(b.promotion_group_name, undefined, { numeric: true, sensitivity: 'base' }));
            });

            if (Object.keys(sortedGroup).length > 0) {
                group.products = sortedGroup;
                result.push(group);
            }
        });
    });

    // Prioritize entries containing "Beer" in the promotionName for final result sorting
    result.sort((a: any, b: any) => {
      const aContainsBeer = a.promotionName.toLowerCase().includes('beer');
      const bContainsBeer = b.promotionName.toLowerCase().includes('beer');
      const aIsLA = a.promotionName.includes('Liquor Alliance');
      const bIsLA = b.promotionName.includes('Liquor Alliance');
      const aContainsEDV = a.promotionName.toLowerCase().includes('edv');
      const bContainsEDV = b.promotionName.toLowerCase().includes('edv');

      if (aContainsEDV && !bContainsEDV) {
          return 1;
      } else if (!aContainsEDV && bContainsEDV) {
          return -1;
      } else if (aContainsBeer && !bContainsBeer) {
          return -1;
      } else if (!aContainsBeer && bContainsBeer) {
          return 1;
      } else if (aIsLA && !bIsLA) {
          return -1;
      } else if (!aIsLA && bIsLA) {
          return 1;
      } else {
          return a.promotionName.localeCompare(b.promotionName, undefined, { numeric: true, sensitivity: 'base' });
      }
  });

    console.log(result);
    return result;  // Return the final structured and sorted array
}



groupByPromotionsandCategory(products: any[], promotionHeaders: any): any[] {
    const groupedByPromotion: any = {};

    // Initialize all groups with promotion headers
    for (const header of promotionHeaders) {
        const key = `${header.id}`;  // Using id as the unique key
        groupedByPromotion[key] = {
            promotionName: header.description,  // Display name is the description
            categories: {},  // New nested structure for categories
            promotion_start: header.promotion_start,
            promotion_end: header.promotion_end
        };
    }

    // Populate products into the initialized groups
    for (const product of products) {
        const productPromotionId = product.promotion_header_id.toString();  // Match string keys
        const categoryName = product.planning_category_name || "Undefined";  // Default category
        const storePosition = product.store_position_name || "Other";  // Default store position

        if (groupedByPromotion.hasOwnProperty(productPromotionId)) {
            let categories = groupedByPromotion[productPromotionId].categories;

            // Initialize category if not already present
            if (!categories[categoryName]) {
                categories[categoryName] = {
                    floor: [],
                    shelf: []
                };
            }

            // Add product to correct position array
            categories[categoryName][storePosition.toLowerCase() === 'shelf' ? 'shelf' : 'floor'].push(product);

            // Optional: Update promotion dates from products
        } else {
            console.warn(`Product with ID '${productPromotionId}' does not match any known promotion header.`);
        }
    }

    // Sort and consolidate all categories and positions
    Object.values(groupedByPromotion).forEach((group: any) => {
        Object.values(group.categories).forEach((category: any) => {
            category.floor.sort((a: any, b: any) => a.promotion_group_name.localeCompare(b.promotion_group_name, 'en', { numeric: true }));
            category.shelf.sort((a: any, b: any) => a.promotion_group_name.localeCompare(b.promotion_group_name, 'en', { numeric: true }));
        });
    });

    // Convert grouped object to array and sort by promotionName
    const result = Object.values(groupedByPromotion);
    return result.sort((a: any, b: any) => a.promotionName.localeCompare(b.promotionName, 'en', { numeric: true }));
}


  groupByPromotions(products: any[], promotionHeaders: any): any[] {
    const groupedByPromotion: any = {};
    console.log("summary before group: " ,products)
    // Initialize all groups with promotion headers to ensure every unique header is considered
    for (const header of promotionHeaders) {
        const key = `${header.id}`;  // Using id as the unique key
        groupedByPromotion[key] = {
            promotionName: header.description,  // Display name is the description
            products: [],  // Initially no products
            promotion_start: header.promotion_start,  // Default empty
            promotion_end: header.promotion_end     // Default empty
        };
    }

    // Populate products into the initialized groups
    for (const product of products) {
        const productPromotionId = product.promotion_header_id.toString();  // Convert to string to match string keys

        // Check if the product matches the specific group by ID
        if (groupedByPromotion.hasOwnProperty(productPromotionId)) {
            groupedByPromotion[productPromotionId].products.push(product);
            // Update promotion dates if applicable
            if (product.promotion_start && !groupedByPromotion[productPromotionId].promotion_start) {
                groupedByPromotion[productPromotionId].promotion_start = product.promotion_start;
            }
            if (product.promotion_end && !groupedByPromotion[productPromotionId].promotion_end) {
                groupedByPromotion[productPromotionId].promotion_end = product.promotion_end;
            }
        } else {
            console.warn(`Product with ID '${productPromotionId}' does not match any known promotion header.`);
        }
    }

   // Assuming groupedByPromotion is an object where each key is a promotion ID and each value is an object containing promotion details and a products array

// Modify the sorting line within the forEach loop
Object.values(groupedByPromotion).forEach((group: any) => {
    // Sort the products array by 'promotion_group_name' using the naturalSort function
    group.products.sort((a: any, b: any) => this.naturalSorts(a, b, 'promotion_group_name'));
});


    // Ensure each group has at least one product, add a default product if empty
    Object.keys(groupedByPromotion).forEach(key => {
        if (groupedByPromotion[key].products.length === 0) {
            groupedByPromotion[key].products.push({
                buying_group_name: "",
                planning_category_name: "",
                price: "",
                promotion_end: groupedByPromotion[key].promotion_end,
                promotion_group_id: '', // Adjust or obtain from header if necessary
                promotion_group_name: "No items in this promotion",
                promotion_header_id: parseInt(key), // The key as ID
                promotion_name: "",
                promotion_period_id: '',
                promotion_start: groupedByPromotion[key].promotion_start,
                store_position_name: ""
            });
        }
    });

    const result = Object.values(groupedByPromotion);  // Convert grouped object to array
    const sortedResult = this.sortPromotionsBy(result, 'promotionName');
    console.log(sortedResult);
    return sortedResult;
}
sortPromotionsBy(promotions:any, sortKey:any) {
    return promotions.sort((a:any, b:any) => this.naturalSorts(a, b, sortKey));
}
groupByCategory(data: any[]): any {
    const grouped: any = {};
    data.forEach(item => {
        if (!grouped[item.product_category_name]) {
            grouped[item.product_category_name] = { sort_order: item.category_sort_order, items: [] };
        }
        grouped[item.product_category_name].items.push(item);
    });
    return grouped;
}

 naturalSorts(a: any, b: any, sortKey: string) {
    const baseRegex = /(\d+)$/; // Regex to find trailing numbers
    let baseA = a[sortKey].replace(baseRegex, '').trim();
    let baseB = b[sortKey].replace(baseRegex, '').trim();

    if (baseA === baseB) {
        let numA = a[sortKey].match(baseRegex);
        let numB = b[sortKey].match(baseRegex);

        if (numA && numB) {
            return parseInt(numA[1], 10) - parseInt(numB[1], 10);
        }
        if (numA) return -1; // A has a number, sort A before B
        if (numB) return 1;  // B has a number, sort B after A
    }

    return baseA.localeCompare(baseB);
}


}


