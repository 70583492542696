import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CostServiceService {

  constructor() { }

  calculateGpAmount(row: Product): number
  {
    if(row.cost_ex_gst !== null && row.sell_price !== null) {
      let newCost = this.calculateNewCost(row) || 0;
      newCost = newCost / row.packs_per_carton;
      const sellPrice = row.sell_price;
      const gpAmount = this.sellPriceExGST(sellPrice) - newCost;
      return gpAmount;
    }
    return 0;
  }

  calculateGpPercent(row: Product)
  {
    if(row.sell_price !== null && row.cost_ex_gst !== null) {
      const gst = row.sell_price / 11;
      const ex_gst = row.sell_price - gst;
      let gpAmount = this.calculateGpAmount(row);
      const percentage = (gpAmount / ex_gst) * 100;
      return percentage.toFixed(2) + '%';
    }
    return 0;
  }

  calculateNewCost(row: Product)
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    }

    if(row.allowance_total !== null && row.cost_ex_gst !== null) {
      if(row.has_wet === 1) {
        return (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))) + (0.29 * (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))));
      } else {
        return row.cost_ex_gst - (allowance_total + (+row.allowance_universal));
      }
    }
    return undefined;
  }

  calculateCostExGst(row: Product)
  {
    if(row.cost_inc_gst !== null && row.cost_inc_gst !== 0) {
      return (row.cost_inc_gst / row.packs_per_carton) / 1.1;
    }
    return undefined;
  }

  getRebateCost(row: Product)
  {
    //API is calculating allowance for mix_match_items
    let allowance_total = 0;
    let rebatedCost = 0;
    let rebateAmountPerPack = 0;
    if(row.mix_match_header_id === null || row.mix_match_header_id === undefined) {
      allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance_universal || 0) + (+row.allowance || 0);
    } else {
      allowance_total = (+row.allowance_total || 0);
    };
    if(row.has_wet === 1) {
      rebatedCost = (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))) + (0.29 * (row.cost_ex_gst - (allowance_total + (+row.allowance_universal))));
    } else {
      rebatedCost = row.cost_ex_gst - (allowance_total + (+row.allowance_universal));
    }
    if(row.rebate !== null && row.rebate !== 0) {
      rebateAmountPerPack = row.rebate / row.packs_per_carton;
    }
    rebatedCost = rebatedCost / row.packs_per_carton;
    if('purchase_quantity' in row && row.purchase_quantity !== null) {
      rebatedCost = rebatedCost * row.purchase_quantity;
    }
    if('multi_buy' in row && row.multi_buy !== null && row.multi_buy !== 'Mix n Match') {
      rebatedCost = rebatedCost * Number(row.multi_buy.substring(0, 1));
    }
    return rebatedCost - rebateAmountPerPack;
  }

  calculateRebateCost = (row: Product) =>
  {
    return this.getRebateCost(row);
  }

  sellPriceExGST(sell_price: number) {
    return sell_price / 1.1;
  }

  calculateRebateGpAmount = (row: Product) =>
  {
    let rebateCost = this.getRebateCost(row);
    if(row.sell_price !== null) {
      let sellPrice = row.sell_price;
      const rebateGpAmount = this.sellPriceExGST(sellPrice) - rebateCost;
      return rebateGpAmount;
    }
    return undefined;
  }

  calculateRebateGpPercent = (row: Product) =>
  {
    if(row.sell_price !== null && row.sell_price !== 0) {
      const rebateGpAmount = this.calculateRebateGpAmount(row) || 0;
      const ex_gst = this.sellPriceExGST(row.sell_price);
      const percentage =  (rebateGpAmount / ex_gst) * 100;
      return percentage.toFixed(2) + '%';
    }
    return undefined;
  }
}
